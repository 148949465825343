import React from 'react';

const AnnouncementsList = ({ announcements }) => {
    return (
        <div className="announcements-list">
            {announcements.map((announcement, index) => (
                <div key={index} className="announcement-item">
                    <h4 className="announcement-title">{announcement.title}</h4>
                    <p className="announcement-detail">{announcement.detail}</p>
                </div>
            ))}
        </div>
    );
};

export default AnnouncementsList;
