import React from 'react';
import { Col } from 'react-bootstrap';

const VideoEmbed = ({ videos }) => {
    return (
        <>
            {videos.map((video, index) => (
                <Col md={6} key={index}>
                    <div className="video-container">
                        <iframe
                            width="100%"
                            height="315"
                            src={video.url}
                            title={`Video ${index + 1}`}
                        />
                    </div>
                </Col>
            ))}
        </>
    );
};

export default VideoEmbed;
