import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import '../Css/Footer.css'; // Stiller için dosya
import logo from '../logo.svg';

const Footer = () => {
  return (
    <footer className="footer bg-light py-4">
      <Container>
        <Row>
          {/* TUSAG Amblemi, Misyon - Vizyon */}
          <Col md={4}>
            <img
              src={logo} // TUSAG logosunun URL'si
              alt="TUSAG Logo"
              style={{ width: '300px' }}
            />
            {/*<h5>Misyon & Vizyon</h5>*/}
            <p>
              Vakfımız, Şehit Aileleri ve Gazilerimize Eğitim, Sağlık ve İstihdam Olanakları Sağlamak İçin kurulmuştur.
            </p>
          </Col>

          {/* TUSAG Bölümü ve Alt Menüler */}
          <Col md={2}>
            <h5>TUSAG</h5>
            <ul className="list-unstyled">
              <li><Link to="/tanitim">Tanıtım</Link></li>
              <li><Link to="/baskanin-mesaji">Başkanın Mesajı</Link></li>
              <li><Link to="/haberler">Haberler</Link></li>
              <li><Link to="/duyurular">Duyurular</Link></li>

            </ul>
          </Col>

          {/* Bağışlar Bölümü ve Bağış Türleri */}
          <Col md={2}>
            <h5>Bağışlar</h5>
            <ul className="list-unstyled">
              <li><Link to="/burslarimiz">Burslarımız</Link></li>
              <li><Link to="/bagislar">Bağış</Link></li>
              <li><Link to="/banka-hesaplarimiz">Banka Hesaplarımız</Link></li>
            </ul>
          </Col>

          {/* İletişim ve Sosyal Medya */}
          <Col md={3}>
            <h5>İletişim</h5>
            <p> <a href="https://www.google.com/maps?q=Bağcılar, Şanlıurfa Blv. Yanyolu No:139 A BLOK, 21090 Bağlar/Diyarbakırhttps://www.google.com/maps/place/Bu%C4%9Fdayc%C4%B1lar+Plaza/@37.927742,40.1743627,17z/data=!3m1!4b1!4m6!3m5!1s0x40751f078b53658b:0x6e5a62b0ac25b0cc!8m2!3d37.927742!4d40.1743627!16s%2Fg%2F11cpnwmnzn?entry=ttu&g_ep=EgoyMDI0MDkxNS4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noopener noreferrer"><FaMapMarkerAlt /></a> Buğdaycılar Plaza A/44</p>
            <p><a href="tel:+02121234567"><FaPhone /> </a> (0212) 123 45 67</p>
            <p> <a href="mailto:info@tugas.org"><FaEnvelope /></a> info@tugas.org</p>

          </Col>
          {/* Sosyal Medya */}
          <Col md={1}>
            <div className="social-icons">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} className="d-block mb-2" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <FaTwitter size={30} className="d-block mb-2" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} className="d-block mb-2" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                <FaYoutube size={30} className="d-block mb-2" />
              </a>
            </div>
          </Col>
        </Row>
        {/* Copyright */}
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0">© 2024 MDSiber. Tüm Hakları Saklıdır.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
