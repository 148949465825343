import React from 'react';
import { Carousel } from 'react-bootstrap';

const ImageCarousel = ({ images, captions }) => {
    return (
        <Carousel>
            {images.map((image, index) => (
                <Carousel.Item key={index}>
                    <img className="d-block w-100 carousel-image" src={image} alt={`Slide ${index + 1}`} />
                    <Carousel.Caption>
                        <h3>{captions[index].title}</h3>
                        <p>{captions[index].text}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default ImageCarousel;
