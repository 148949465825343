import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import '../Css/GotoTop.css'; // Stil dosyasını import edin

const GoToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const checkScrollTop = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={`go-to-top ${isVisible ? 'visible' : ''}`} onClick={scrollToTop}>
            <FaArrowUp size={24} />
        </div>
    );
};

export default GoToTop;
