import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';

const Header = () => {
    return (
        <Navbar bg="light" expand="lg" className="shadow-sm">
            <Container>
                {/* Logo solda olacak şekilde hizalanıyor */}
                <Navbar.Brand as={Link} to="/">
                    <img
                        src={logo}
                        alt="Vakıf Logo"
                        style={{ width: '150px' }}
                    />
                </Navbar.Brand>

                {/* Toggle butonu, mobilde menüyü açıp kapatmak için */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                {/* Menü elemanlarını sağa hizalamak için justify-content-end ekledik */}
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link as={Link} to="/" className="nav-item">Ana Sayfa</Nav.Link>
                        <Nav.Link as={Link} to="/about" className="nav-item">Hakkımızda</Nav.Link>
                        <Nav.Link as={Link} to="/projects" className="nav-item">Projeler</Nav.Link>
                        <Nav.Link as={Link} to="/projects" className="nav-item">Haklar </Nav.Link>
                        <Nav.Link as={Link} to="/donate" className="nav-item">Bağış</Nav.Link>
                        <Nav.Link as={Link} to="/contact" className="nav-item">İletişim</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
