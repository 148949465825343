import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ImageCarousel from '../utils/ImageCarousel';
import NewsCarousel from '../utils/NewsCarousel';
import AnnouncementsList from '../utils/AnnouncementsList';
import ImageGallery from '../utils/ImageGallery';
import VideoEmbed from '../utils/VideoEmbed';
import '../Css/Home.css'; // Stil dosyasını ayrı bir dosyaya ekleyebiliriz
import guncel from '../assets/guncel.png';
import resim1 from '../assets/galery/resim1.jpg';
import resim2 from '../assets/galery/resim2.jpg';
import resim3 from '../assets/galery/resim3.jpg';
import resim4 from '../assets/galery/resim4.jpg';
import resim5 from '../assets/galery/resim5.jpg';
import resim6 from '../assets/galery/resim6.jpg';
import resim7 from '../assets/galery/resim7.jpg';
import resim8 from '../assets/galery/resim8.jpg';
import resim9 from '../assets/galery/resim9.jpg';
import haber1 from '../assets/haber/haber1.jpg';
import haber2 from '../assets/haber/haber2.jpg';
import haber3 from '../assets/haber/haber3.jpg';

const Home = () => {
    const carouselImages = [guncel, guncel, guncel];
    const carouselCaptions = [
        { title: '', text: '' },
        { title: '', text: '' },
        { title: '', text: '' },
    ];

    const newsItems = [
        {
            title: 'Şehit Anne Ve Babalarımız İle Birlikte...',
            text: '',
            image: haber1
        },
        {
            title: 'Vatan Yürekli İnsan Sedat Şahin...',
            text: '.',
            image: haber2
        },
        {
            title: 'Şehitlerimiz',
            text: '',
            image: haber3
        }
    ];

    const announcements = [
        {
            title: 'Vefa Ziyaretimiz',
            detail: 'Şehit ve gazi ailelerimizi evlerinde ziyaret ederek, her zaman yanlarında olduğumuzu bir kez daha dile getirdik. Birlik ve beraberlik içinde geçen ziyaretlerimizde duygu dolu anlar yaşandı.'
        },
        {
            title: 'Anma Töreni Duyurusu',
            detail: 'Aziz şehitlerimiz ve kahraman gazilerimizi anmak için düzenleyeceğimiz törene tüm halkımızı davet ediyoruz. Katılımınız bizler için onur verici olacaktır.'
        },
        {
            title: 'Başkanımızdan Teşekkür Mesajı',
            detail: 'Vakfımıza gösterilen destek ve ilgi için tüm gönüldaşlarımıza teşekkür ederiz. Birlikte daha güçlü ve kararlı adımlarla yürümeye devam edeceğiz.'
        },
        {
            title: 'Yardım Kampanyası Başladı',
            detail: 'Vakfımızın düzenlediği yeni yardım kampanyasında şehit ve gazi ailelerimiz için yardımlar toplanmaya başlandı. Destek olmak isteyen tüm gönüllülerimizi katkıda bulunmaya davet ediyoruz.'
        },
        {
            title: 'Genel Kurul Toplantısı Duyurusu',
            detail: 'Vakfımızın yıllık genel kurul toplantısı yaklaşıyor. Tüm üyelerimizi, gelecek dönem hedeflerini ve projelerini konuşmak üzere toplantımıza davet ediyoruz.'
        }
    ];
    const galleries = [
        [resim1, resim2, resim3],
        [resim4, resim5, resim6],
        [resim7, resim8, resim9],
    ];

    const videos = [
        { url: 'https://www.youtube.com/embed/wEcEc5TAOL0' },
        { url: 'https://www.youtube.com/embed/Odl2qwbrFwg' },
    ];

    return (
        <Container fluid className="home-page">
            {/* Güncel Durum Carousel */}
            <Row className="mb-4">
                <Col>
                    <ImageCarousel images={carouselImages} captions={carouselCaptions} />
                </Col>
            </Row>

            {/* Haberler (Kaydırmalı) */}
            <Row className="mb-4 section-content">
                <Col>
                    <h2>Haberler</h2>
                    <NewsCarousel className="haber" newsItems={newsItems} />
                </Col>
            </Row>

            {/* Duyurular (Başlık ve Detay) */}
            <Row className="mb-4 section-content">
                <Col>
                    <h2>Duyurular</h2>
                    <AnnouncementsList announcements={announcements} />
                </Col>
            </Row>
            {/* Banner */}
            <Row className="mb-4 ">
                <Col>
                    <div className="banner" />
                </Col>
            </Row>

            {/* Galeri */}
            <Row className="mb-4 section-content">
                <h4>Fotograflar</h4>
                <ImageGallery galleries={galleries} />
            </Row>

            {/* Videolar */}
            <Row className="mb-4 section-content">
                <h4>Videolar</h4>
                <VideoEmbed videos={videos} />
            </Row>
        </Container>
    );
};

export default Home;
