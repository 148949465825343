import React from 'react';
import { Carousel, Col } from 'react-bootstrap';

const ImageGallery = ({ galleries }) => {
    return (
        <>
            {galleries.map((gallery, index) => (
                <Col md={4} key={index}>
                    <Carousel>
                        {gallery.map((image, imgIndex) => (
                            <Carousel.Item key={imgIndex}>
                                <img className="d-block w-100 gallery-image" src={image} alt={`Gallery Image ${imgIndex + 1}`} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Col>
            ))}
        </>
    );
};

export default ImageGallery;
