import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1>Hakkımızda</h1>
          <p>Vakfımız, Şehit Aileleri ve Gazilerimize Eğitim, Sağlık ve İstihdam Olanakları Sağlamak İçin kurulmuştur.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
